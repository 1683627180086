import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { Plan } from '@wix/ambassador-checkout-server/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { Booking } from '@wix/bookings-checkout-api';
import { Service } from '@wix/bookings-uou-types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { bookingsCalendarErrorMessages } from '@wix/bi-logger-wixboost-ugc/v2';
import { FlowElements } from './Hooks/useFlow';
import { EmptyStateType } from './ViewModel/emptyStateViewModel/emptyStateViewModel';
import { BottomSectionStatus } from './ViewModel/widgetViewModel/widgetViewModel';
import {
  DialogState,
  DialogType,
} from './ViewModel/dialogViewModel/dialogViewModel';
import {
  CalendarViewModel,
  createMemoizedCalendarViewModelFactory,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { CalendarApi } from '../../api/CalendarApi';
import {
  createControlledComponent,
  Render,
} from '../../utils/ControlledComponent/ControlledComponent';
import { SelectedBookingPreference } from '../../utils/bookingPreferences/bookingPreferences';
import { navigateToServicePageIfNeeded } from '../../utils/navigation/navigation';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { createInitialState } from '../../utils/state/initialStateFactory';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';
import { getUpdatedStateOverEditor } from '../../utils/editorBehaviors/editorBehaviors';

import {
  CalendarErrors,
  FilterOptions,
  Preset,
  SelectedVariantOptions,
  SlotsStatus,
  TriggeredByOptions,
} from '../../types/types';
import calendarSettingsParams from './settingsParams';
import { isCalendarPage } from '../../utils/presets';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  bottomSectionStatus: BottomSectionStatus;
  slotsStatus: SlotsStatus;
  availableServices: Service[];
  selectedDate?: string;
  selectedDateTrigger?: TriggeredByOptions;
  selectedTimezone?: string;
  selectedRange?: {
    from: string;
    to: string;
  };
  selectedTime?: string;
  selectableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  availableSlotsPerDay?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: FilterOptions;
  focusedElement?: FlowElements;
  initialErrors: EmptyStateType[];
  purchasedPricingPlans: Plan[];
  isUserLoggedIn: boolean;
  selectedVariantsOptions: SelectedVariantOptions[];
  serviceVariants?: ServiceOptionsAndVariants;
  hasReferralParam?: boolean;
};

export const createControllerFactory = (
  preset: Preset,
  settingsParams: any,
) => {
  const createController: CreateControllerFn = async ({
    flowAPI,
  }: ControllerParams) => {
    const { controllerConfig, experiments, reportError } = flowAPI;
    const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
    let rerender: Render<CalendarState> = async () => {};
    let publicData = controllerConfig.config.publicData.COMPONENT || {};
    let calendarContext: CalendarContext;
    let initialState: CalendarState;
    let service: Service;

    return {
      async pageReady() {
        const { reportError } = flowAPI;
        const isCalendarPagePreset = isCalendarPage(preset);

        const calendarApi = new CalendarApi({
          wixSdkAdapter,
          reportError,
          flowAPI,
          settingsParams,
          preset,
        });

        const initialErrors: EmptyStateType[] = [];

        const onError = (type: EmptyStateType) => initialErrors.push(type);

        const isAnonymousCancellationFlow =
          wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRAL) ===
          'batel';

        const isShowPricingPlanEndDateIndicationEnabled = experiments.enabled(
          'specs.bookings.ShowPricingPlanEndDateIndication',
        );
        const isAnywherePublicDataOverrides = experiments.enabled(
          'specs.bookings.anywherePublicDataOverrides',
        );

        const currentUser = controllerConfig.wixCodeApi.user.currentUser;
        const shouldGetPurchaserPricingPlans =
          isShowPricingPlanEndDateIndicationEnabled && currentUser.loggedIn;
        const [
          catalogData,
          rescheduleBookingDetails,
          allPurchasedPricingPlans,
          isPricingPlanInstalled,
          isMemberAreaInstalled,
        ] = await Promise.all([
          calendarApi.getCatalogData({ onError }),
          calendarApi.getBookingDetails({
            onError: (type: EmptyStateType) => {
              if (!isAnonymousCancellationFlow) {
                onError(type);
              }
            },
          }),
          shouldGetPurchaserPricingPlans
            ? calendarApi.getPurchasedPricingPlans({
                contactId: currentUser.id,
              })
            : [],
          wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
          wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
        ]);

        const availableServices = catalogData?.services || [];
        service = availableServices[0];
        if (isCalendarPagePreset && availableServices?.length) {
          await navigateToServicePageIfNeeded(
            availableServices[0],
            wixSdkAdapter,
          );
        }

        initialState = createInitialState({
          services: availableServices,
          staffMembers: catalogData?.staffMembers,
          wixSdkAdapter,
          rescheduleBookingDetails,
          initialErrors,
          isAnonymousCancellationFlow,
          isAnywherePublicDataOverrides,
          allPurchasedPricingPlans,
          isShowPricingPlanEndDateIndicationEnabled,
          isPricingPlanInstalled,
          isUserLoggedIn: currentUser.loggedIn,
          serviceVariants: catalogData?.serviceVariants,
        });
        calendarContext = await createCalendarContext({
          flowAPI,
          businessInfo: catalogData?.businessInfo,
          activeFeatures: catalogData?.activeFeatures,
          calendarApi,
          wixSdkAdapter,
          initialState,
          isPricingPlanInstalled,
          isMemberAreaInstalled,
          settingsParams,
          preset,
        });

        const { onStateChange, render, controllerActions, setState } =
          await createControlledComponent<
            CalendarState,
            CalendarActions,
            CalendarViewModel,
            CalendarContext
          >({
            controllerConfig,
            initialState,
            viewModelFactory: createMemoizedCalendarViewModelFactory(
              wixSdkAdapter.isEditorMode(),
            ),
            actionsFactory: createCalendarActions,
            context: calendarContext,
          });
        rerender = render;
        if (
          isAnonymousCancellationFlow &&
          !controllerConfig.wixCodeApi.user.currentUser.loggedIn &&
          !wixSdkAdapter.isSSR() &&
          // @ts-expect-error
          wixSdkAdapter.getUrlQueryParamValue('bookingId')
        ) {
          setTimeout(async () => {
            try {
              // @ts-expect-error
              await controllerConfig.wixCodeApi.user.promptLogin();
            } catch (e) {
              //
            }

            const updatedRescheduleBookingDetails =
              await calendarApi.getBookingDetails({
                onError,
              });
            setState({
              rescheduleBookingDetails: updatedRescheduleBookingDetails,
            });
          }, 10);
        }

        await wixSdkAdapter.renderSeoTags(
          ITEM_TYPES.BOOKINGS_CALENDAR,
          catalogData?.seoData?.[0],
        );

        const { biLogger } = calendarContext;

        if (!wixSdkAdapter.isSSR()) {
          onStateChange((state) => {
            biLogger.update(state);
          });
        }

        if (isShowPricingPlanEndDateIndicationEnabled && isCalendarPagePreset) {
          wixSdkAdapter.onUserLogin(controllerActions.onUserLoggedIn);
        }

        const serviceNotFound = initialState.initialErrors.includes(
          EmptyStateType.SERVICE_NOT_FOUND,
        );
        if (serviceNotFound) {
          void biLogger.report(
            bookingsCalendarErrorMessages({
              errorMessage: 'SERVICE_NOT_FOUND',
            }),
          );
        }
      },
      exports() {
        const wrapOverrideCallback = (
          sdkMethod: keyof WixOOISDKAdapter,
          callback: (data: any) => Promise<any>,
        ) => {
          const originalSdkMethod: Function =
            wixSdkAdapter[sdkMethod].bind(wixSdkAdapter);
          (wixSdkAdapter[sdkMethod] as any) = function (data: any) {
            const originalArguments = arguments;
            return callback(data).then(async ({ shouldNavigate = true } = {}) =>
              shouldNavigate
                ? originalSdkMethod.apply(
                    originalSdkMethod,
                    originalArguments as any,
                  )
                : null,
            );
          };
        };
        return {
          onNextClicked(overrideCallback: Function) {
            wrapOverrideCallback(
              'navigateToBookingsFormPage',
              ({ slotAvailability }) =>
                overrideCallback({
                  service,
                  slotAvailability,
                }),
            );
            wrapOverrideCallback('navigateToMembersArea', () =>
              overrideCallback({
                service,
              }),
            );
          },
        };
      },
      async updateConfig($w, newConfig) {
        const updatedPublicData = newConfig.publicData.COMPONENT || {};
        const isCalendarLayoutChanged =
          publicData.calendarLayout !== updatedPublicData.calendarLayout;
        const isLocationsSelectionChanged =
          publicData.selectedLocations !== updatedPublicData.selectedLocations;
        publicData = updatedPublicData;

        const isShowRealCalendarDataOnEditorEnabled =
          flowAPI.experiments.enabled(
            'specs.bookings.showRealCalendarDataOnEditor',
          );

        if (
          isShowRealCalendarDataOnEditorEnabled &&
          isLocationsSelectionChanged
        ) {
          await rerender({
            updatedState: {
              slotsStatus: SlotsStatus.LOADING,
            },
          });
          const updatedState = await getUpdatedStateOverEditor({
            state: initialState,
            context: calendarContext,
          });
          await rerender({
            updatedState,
          });
        } else {
          await rerender({
            resetState: isCalendarLayoutChanged,
          });
        }
      },
    };
  };

  return createController;
};

export default createControllerFactory(
  Preset.CALENDAR_PAGE,
  calendarSettingsParams,
);
