import { CalendarState } from '../../components/BookingCalendar/controller';
import { getTodayLocalDateTimeStartOfDay } from '../dateAndTime/dateAndTime';
import { getLocalDateTimeRangeForDay } from '../getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import { CalendarContext } from '../context/contextFactory';
import { SlotsStatus } from '../../types/types';

const clearCalendarErrors = (state: CalendarState) => {
  const { initialErrors, calendarErrors } = state;

  initialErrors.splice(0, initialErrors.length);
  calendarErrors.splice(0, calendarErrors.length);
};

export const getUpdatedStateOverEditor = async ({
  state,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}): Promise<Partial<CalendarState>> => {
  const { businessInfo, calendarApi } = context;
  const { initialErrors, calendarErrors, selectedTimezone } = state;

  clearCalendarErrors(state);

  const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(selectedTimezone!);
  const range = getLocalDateTimeRangeForDay(
    businessInfo!.regionalSettingsLocale!,
    todayLocalDateTime,
  );

  const catalogData = await calendarApi.getCatalogData({
    onError: (type) => initialErrors.push(type),
  });
  const availableServices = catalogData?.services || [];

  const availableSlots = await calendarApi.getSlotsInRange(range, {
    state: {
      ...state,
      availableServices,
    },
    onError: (type) => calendarErrors.push(type),
  });

  return {
    availableServices,
    availableSlots,
    initialErrors,
    calendarErrors,
    slotsStatus: SlotsStatus.AVAILABLE_SLOTS,
  };
};
